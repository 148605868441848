$(document).ready(function() {
  AOS.init();

  $('.venobox').venobox();

  setTimeout(() => {
    initMobileMenu();
    initBannerSwiper();
    initAdsSwiper();

    initMasonryGrid();

    imgToBG($('.bg-img'), '.img-bg');

    moveModalToBody('#search-modal');
    moveModalToBody('#newsletter-modal');

    if ($('.alert').length) {
      $('html, body').animate(
        {
          scrollTop: $('.alert')
            .first()
            .offset().top,
        },
        1000
      );
    }
  }, 500);

  $('#newsletter-modal').on('show.bs.modal', function() {
    var email = $('#txtNewsletterEmailInit').val();

    $('#newsletterform')
      .find('#txtNewsletterEmail')
      .val(email);
  });

  $('#newsletterinit').submit(function(event) {
    event.preventDefault();

    var email = $('#txtNewsletterEmailInit').val();

    if (!isValidEmailAddress(email)) {
        alert('Invalid email address.');
    } else {
      $('#newsletter-modal').modal('show');
    }
  });

  $('#newsletterform').submit(function(event) {
    event.preventDefault();

    $('#alert-result').html('');

    var $this = $(this);
    var name = $this.find('#txtNewsletterName');
    var email = $this.find('#txtNewsletterEmail');
    var code = $('#g-recaptcha-response');
    var action = $this.find("input[name='txtNewsletter']:checked").attr('value');

    if (action == 1) {
      action = 'subscribe';
    } else {
      action = 'unsubscribe';
    }

    $.ajax({
      type: 'POST',
      url: 'ajax.php',
      data: {
        type: 'newsletter_subscribe',
        txtName: name.val(),
        txtEmail: email.val(),
        grecaptcharesponse: code.val(),
        action: action,
      },
      success: function(data) {
        $('#alert-result').html('');
        if (data) {
          $('#alert-result').html(data);

          if (data.indexOf('success') !== -1) {
            setTimeout(function() {
              $('#newsletter-modal').modal('hide');
            }, 1000);
          }
        }
      },
    });
  });

  $('#newsletter-modal').on('hide.bs.modal', function() {
    $('#newsletterform')
      .find('#txtNewsletterName')
      .val('');

    $('#newsletterform')
      .find('#txtNewsletterEmail')
      .val('');

    $('#alert-result').html('');

    grecaptcha.reset();
  });
});

$(window).on('load', function() {});

function initMobileMenu() {
  var mmenu = mmlight($('#mobile-menu').get(0));

  mmenu.create('(max-width: 991px)');
  mmenu.init('current');

  $('a[href="#mobile-menu"]').on('click', function(ev) {
    mmenu.open();

    ev.preventDefault();
    ev.stopPropagation();
  });
}

function initMasonryGrid() {
  if ($('.masonry-grid').length) {
    $('.masonry-grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      gutter: '.gutter-sizer',
    });
  }
}

function initBannerSwiper() {
  if ($('#banner').length) {
    var bannerSwiper = new Swiper('#banner', {
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}

function initAdsSwiper() {
  if ($('#ads').length) {
    var adsSwiper = new Swiper('#ads .swiper-container', {
      autoplay: {
        delay: 5000,
      },
    });
  }
}

function imgToBG(ele, _imgClass) {
  if (ele.length > 0) {
    ele.each(function() {
      var _this = $(this),
        img = _this.find(_imgClass).eq(0) || _this.find('img').eq(0);
      _this.css({
        'background-image': 'url("' + img.attr('src') + '")',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      });
      img.hide();
    });
  }
}

function moveModalToBody(modal) {
  if ($(modal).length) {
    setTimeout(function() {
      $(modal).appendTo('body');
    }, 500);
  }
}

function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
  return pattern.test(emailAddress);
}

window.captchaCallback = function (response) {
  $('#g-recaptcha-response').val(response);
}
